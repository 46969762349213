import { showNotificationWithTTL } from "actions/notifications"
import { selectVisibleUserAgentsIds } from "reducers/userAgents"
import deleteAgentCall from "api/agents/deleteAgentCall"
import updateAgentsCall from "api/agents/updateAgentsCall"
import loadAgentsStatusCall from "api/agents/loadAgentsStatusCall"
import loadAgentsCall from "api/agents/loadAgentsCall"
import { loadFilteringSchedules } from "actions/filteringSchedules"
import { loadPolicies } from "actions/filtering"
import { loadBlockPages } from "actions/blockPages"
import {
  LOAD_USER_AGENTS,
  TOGGLE_EDIT_MULTIPLE_USER_AGENTS_FORM,
  CHANGE_USER_AGENTS_SEARCH_TEXT_FILTER,
  CHANGE_USER_AGENTS_STATUS_FILTER,
  CHANGE_ALL_USER_AGENTS,
  SELECT_AGENT,
  CHANGE_USER_AGENT_PAGE,
  CHANGE_USER_AGENT,
  CHANGE_MULTIPLE_USER_AGENTS,
  UPDATE_MULTIPLE_USER_AGENTS_REQUEST,
  LOAD_AGENTS_STATUS,
  REMOVE_USER_AGENT,
  DELETE_USER_AGENT_REQUEST,
  REMOVE_MULTIPLE_AGENTS,
  SHOW_CONFIRM_DELETE_CLIENT_MODAL,
  HIDE_CONFIRM_DELETE_CLIENT_MODAL,
  UNSELECT_ALL_USER_AGENTS,
  UPDATE_SELECTED_TAGS,
  SORT_USER_AGENTS,
} from "action-types/userAgents"
import {
  actionCreatorFetch,
  actionCreatorSuccess,
  actionCreatorFailure,
} from "utils/actionCreatorRequest"
import { getActiveOrganization } from "utils/organizations"

export const loadUserAgentsRequest = actionCreatorFetch(LOAD_USER_AGENTS)

export const loadUserAgentsSuccess = actionCreatorSuccess(LOAD_USER_AGENTS)

export const loadUserAgentsFailure = actionCreatorFailure(LOAD_USER_AGENTS)

export const toggleEditMultipleUserAgentsForm = (showForm) => ({
  type: TOGGLE_EDIT_MULTIPLE_USER_AGENTS_FORM,
  payload: showForm,
})

export const updateSearchText = (searchText) => ({
  type: CHANGE_USER_AGENTS_SEARCH_TEXT_FILTER,
  payload: searchText,
})

export const updateStatusFilter = (status) => ({
  type: CHANGE_USER_AGENTS_STATUS_FILTER,
  payload: status,
})

export const updateSelectAllUserAgents = ({ allChecked, visibleIds }) => ({
  type: CHANGE_ALL_USER_AGENTS,
  payload: {
    allChecked,
    visibleIds,
  },
})

export const selectUserAgent = (agentId) => ({
  type: SELECT_AGENT,
  payload: agentId,
})

export const changePage = (page) => ({
  type: CHANGE_USER_AGENT_PAGE,
  payload: page,
})

export const changeUserAgent = (userAgent) => ({
  type: CHANGE_USER_AGENT,
  payload: userAgent,
})

export const changeUserAgents = (userAgents) => ({
  type: CHANGE_MULTIPLE_USER_AGENTS,
  payload: userAgents,
})

export const updateMultipleUserAgentsRequest = actionCreatorFetch(
  UPDATE_MULTIPLE_USER_AGENTS_REQUEST,
)

export const updateMultipleUserAgentsSuccess = actionCreatorSuccess(
  UPDATE_MULTIPLE_USER_AGENTS_REQUEST,
)

export const updateMultipleUserAgentsFailure = actionCreatorFailure(
  UPDATE_MULTIPLE_USER_AGENTS_REQUEST,
)

export const loadAgentsStatusRequest = actionCreatorFetch(LOAD_AGENTS_STATUS)

export const loadAgentsStatusSuccess = actionCreatorSuccess(LOAD_AGENTS_STATUS)

export const loadAgentsStatusFailure = actionCreatorFailure(LOAD_AGENTS_STATUS)

export const loadAgentsStatus = () => (dispatch) => {
  dispatch(
    loadAgentsStatusRequest({
      activeAgentsIds: [],
    }),
  )

  return loadAgentsStatusCall()
    .then((activeAgentsIds) =>
      dispatch(
        loadAgentsStatusSuccess({
          activeAgentsIds,
        }),
      ),
    )
    .catch(() => {
      dispatch(loadAgentsStatusFailure())

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "The was a problem while loading active roaming clients",
          message: "",
          icon: "exclamation-circle",
        }),
      )
    })
}

export const removeUserAgent = (agentId) => ({
  type: REMOVE_USER_AGENT,
  payload: agentId,
})

export const removeUserAgentRequest = actionCreatorFetch(
  DELETE_USER_AGENT_REQUEST,
)

export const removeUserAgentSuccess = actionCreatorSuccess(
  DELETE_USER_AGENT_REQUEST,
)

export const removeUserAgentFailure = actionCreatorFailure(
  DELETE_USER_AGENT_REQUEST,
)

export const removeUserAgents = (agentIds) => ({
  type: REMOVE_MULTIPLE_AGENTS,
  payload: agentIds,
})

export const showConfirmDeleteModal = (agentId) => ({
  type: SHOW_CONFIRM_DELETE_CLIENT_MODAL,
  payload: agentId,
})

export const hideConfirmDeleteModal = (agentId) => ({
  type: HIDE_CONFIRM_DELETE_CLIENT_MODAL,
  payload: agentId,
})

export const loadUserAgents =
  ({
    dispatchRequest = true,
    text,
    status,
    networkId,
    pageNumber,
    sortType,
    tag,
  } = {}) =>
  (dispatch, getState) => {
    const { size } = getState().userAgents
    const page = pageNumber || getState().userAgents.currentPage
    const sortBy = sortType
    if (dispatchRequest) {
      dispatch(
        loadUserAgentsRequest({
          currentPage: page,
          size,
        }),
      )
    }

    return loadAgentsCall(
      networkId,
      text,
      status,
      "agent",
      page,
      size,
      sortBy,
      tag,
    )
      .then((result) => {
        const { data, ...rest } = result

        dispatch(
          loadUserAgentsSuccess({
            data,
            ...rest,
          }),
        )
      })
      .catch(() => {
        dispatch(loadUserAgentsFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "The was a problem while loading active roaming clients",
            message: "",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const updateUserAgent = (userAgent, afterSaveCallback) => {
  userAgent.block_page_id = userAgent.block_page_id || null
  userAgent.policy_id = userAgent.policy_id || null
  userAgent.scheduled_policy_id = userAgent.scheduled_policy_id || null

  return (dispatch, getState) =>
    updateAgentsCall(userAgent).then(
      () => {
        const { organizations } = getState()

        const organization = getActiveOrganization(organizations.data)

        dispatch(
          showNotificationWithTTL(
            {
              type: "success",
              title: `Roaming Client ${
                organization.gdpr
                  ? "[PII Redacted]"
                  : userAgent.friendly_name || userAgent.hostname
              } updated!`,
              message: null,
              icon: "check",
            },
            6,
          ),
        )

        dispatch(changeUserAgent(userAgent))
        dispatch(loadUserAgents())

        if (afterSaveCallback) {
          afterSaveCallback()
        }

        dispatch(loadPolicies(false))
        dispatch(loadFilteringSchedules(false))
        dispatch(loadBlockPages(false))
      },
      (error) => {
        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: userAgent.friendly_name || userAgent.hostname,
            message: `Failed to update "${
              userAgent.friendly_name || userAgent.hostname
            }"`,
          }),
        )

        throw error
      },
    )
}

export const updateMultipleUserAgents =
  (
    {
      selectedUserAgentsIds,
      policy_id,
      scheduled_policy_id,
      block_page_id,
      network_id,
      tags,
    },
    afterSaveCallback,
  ) =>
  (dispatch, getState) => {
    dispatch(updateMultipleUserAgentsRequest())

    const selectedUserAgents = selectedUserAgentsIds.map((agentId) =>
      getState().userAgents.data.find((agent) => agent.id === agentId),
    )
    const updatedAgents = selectedUserAgents.map((agent) => {
      let policyPayload
      let scheduledPolicyPayload
      let blockPagePayload

      if (policy_id === "no-policy" || scheduled_policy_id) {
        policyPayload = null
      } else if (!policy_id) {
        policyPayload = agent.policy_id ? agent.policy_id : null
      } else {
        policyPayload = policy_id
      }

      if (policy_id) {
        scheduledPolicyPayload = null
      } else if (scheduled_policy_id) {
        scheduledPolicyPayload = scheduled_policy_id
      } else {
        scheduledPolicyPayload = agent.scheduled_policy_id
          ? agent.scheduled_policy_id
          : null
      }

      if (!block_page_id) {
        blockPagePayload = agent.block_page_id ? agent.block_page_id : null
      } else if (block_page_id) {
        blockPagePayload =
          block_page_id === "default-appearance" ? null : block_page_id
      }

      return {
        ...agent,
        tags: tags || agent.tags,
        policy_id: policyPayload,
        scheduled_policy_id: scheduledPolicyPayload,
        block_page_id: blockPagePayload,
        network_id: network_id || agent.network_id,
      }
    })
    const requests = updatedAgents.map((agent) => updateAgentsCall(agent))

    Promise.all(requests)
      .then(() => {
        dispatch(
          showNotificationWithTTL(
            {
              type: "success",
              title: "Roaming Clients updated!",
              message: null,
              icon: "check",
            },
            6,
          ),
        )

        dispatch(updateMultipleUserAgentsSuccess())
        dispatch(toggleEditMultipleUserAgentsForm(false))
        dispatch(changeUserAgents(updatedAgents))

        if (afterSaveCallback) {
          afterSaveCallback()
        }

        dispatch(loadPolicies(false))
        dispatch(loadFilteringSchedules(false))
        dispatch(loadBlockPages(false))
      })
      .catch((reason) => {
        dispatch(updateMultipleUserAgentsFailure())

        dispatch(
          showNotificationWithTTL({
            type: "danger",
            title: "Error updating roaming clients.",
            message: "Failed to update.",
            icon: "exclamation-circle",
          }),
        )
      })
  }

export const deleteUserAgent = (userAgent) => (dispatch, getState) => {
  let idToBeDeleted = getState().userAgents.idToBeDeleted

  if (!idToBeDeleted) {
    dispatch(showConfirmDeleteModal(userAgent.id))

    return
  }

  idToBeDeleted = idToBeDeleted || userAgent.id
  dispatch(removeUserAgentRequest(idToBeDeleted))

  return deleteAgentCall(idToBeDeleted).then(
    () => {
      dispatch(
        showNotificationWithTTL(
          {
            type: "success",
            title: "Roaming Client deleted!",
            message: null,
            icon: "check",
          },
          6,
        ),
      )

      dispatch(removeUserAgentSuccess())
      dispatch(hideConfirmDeleteModal())
      dispatch(removeUserAgent(idToBeDeleted))
    },
    (error) => {
      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "Error deleting Roaming Client",
          message: null,
        }),
      )

      throw error
    },
  )
}

export const deleteMultipleAgents = () => (dispatch, getState) => {
  dispatch(removeUserAgentRequest())

  const { userAgents } = getState()

  const sortType = `${userAgents.sortedBy.direction === "desc" ? "-" : ""}${
    userAgents.sortedBy.type
  }`

  const agentIds = selectVisibleUserAgentsIds(getState())
  const requests = agentIds.map((agentId) => deleteAgentCall(agentId))

  Promise.all(requests)
    .then(() => {
      dispatch(
        showNotificationWithTTL(
          {
            type: "success",
            title: "Roaming Clients deleted!",
            message: null,
            icon: "check",
          },
          6,
        ),
      )

      dispatch(loadUserAgents({ sortType }))
      dispatch(removeUserAgentSuccess())
      dispatch(hideConfirmDeleteModal())
      dispatch(removeUserAgents(agentIds))
      dispatch(toggleEditMultipleUserAgentsForm(false))
    })
    .catch((reason) => {
      dispatch(removeUserAgentFailure())

      dispatch(
        showNotificationWithTTL({
          type: "danger",
          title: "Error deleting roaming clients",
          message: "Failed to delete.",
          icon: "exclamation-circle",
        }),
      )
    })
}

export const unselectAllUserAgents = () => ({
  type: UNSELECT_ALL_USER_AGENTS,
})

export const updateSelectedTag = (payload) => ({
  type: UPDATE_SELECTED_TAGS,
  payload,
})

export const sortUserAgents = ({ type, direction }) => ({
  type: SORT_USER_AGENTS,
  payload: {
    type,
    direction,
  },
})
